import React, { useState } from "react";
import { Spin } from "antd";
import { useSetRecoilState } from "recoil";
import { empIdState } from "../states/state";
import { Link, useNavigate } from "react-router-dom";
import VeroltLogo from "../assets/Logo/verolt-logo.png";
import { adminLogin, getOTPEmail } from "../services/api";
import { Card, Col, Row, Input, notification } from "antd";
import {
  EyeInvisibleFilled,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [showSpinner, setShowSpinner] = useState(false);
  const [, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const setEmpId = useSetRecoilState(empIdState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setShowSpinner(true);
    try {
      const response = await adminLogin(formData);
      if (response.status === 200) {
        const admin_emp_id = response.data.emp_id;
        setEmpId(admin_emp_id);
        sessionStorage.setItem("admin_emp_id", admin_emp_id);
        sessionStorage.setItem("isAuthenticated", "true");
        notification.success({
          message: "Success",
          description: "Login Successful",
          showProgress: true,
          pauseOnHover: true,
        });
        setTimeout(() => navigate("/dashboard"), 500);
      } else if (response.status === 401) {
        // unautorized user
        setShowSpinner(false);
        setFormData((prevData) => ({ ...prevData, password: "" }));
        notification.error({
          message: "Error",
          description: response?.data?.response
            ? capitalizeFirstLetter(response.data.response)
            : "Unauthorized",
        });
      } else if (
        // Password Update response check
        response.status === 406 &&
        response?.data?.response === "update password"
      ) {
        const userData = { email: formData.email };
        try {
          const response = await getOTPEmail(userData);
          if (
            response.status === 200 &&
            response?.data?.response === "success"
          ) {
            setShowSpinner(false);
            notification.success({
              message: "Update Password",
              description:
                "Please check your email for otp to reset your password",
              duration: 0,
            });
            setTimeout(
              () =>
                navigate("/verify-otp", {
                  state: { emailId: formData.email, title: "Verify OTP" },
                }),
              500
            );
          } else {
            notification.error({
              message: "Error",
              description:
                response?.data?.response ||
                "Failed to send otp, Please try again",
            });
          }
        } catch (error) {
          setShowSpinner(false);
          console.error("API error details:", error);
          notification.error({
            message: "Error",
            description: "An unexpected error occurred. Please try again.",
          });
        } finally {
          setShowSpinner(false);
        }
      } else {
        setShowSpinner(false);
        setFormData((prevData) => ({ ...prevData, password: "" }));
        notification.error({
          message: "Error",
          description: response?.data?.response
            ? capitalizeFirstLetter(response.data.response)
            : "Login failed",
        });
      }
    } catch (error) {
      setShowSpinner(false);
      notification.error({
        message: "Error",
        description: "Login failed.. Please try again !!",
      });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  return (
    <Spin
      spinning={showSpinner}
      indicator={<LoadingOutlined size={40} spin />}
      size="large"
    >
      <div className={`fs-login ${showSpinner && "blur"}`}>
        <Row className="loginContainer">
          <Col span={24}>
            <Card className="loginCard border-0 bg-transparent text-white">
              <img src={VeroltLogo} width="60" height="50" alt="logo" />
              <h4 className="text-center fw-normal fs-4 text-danger">
                VeSense
              </h4>
              <h2 className="text-center">Admin Login</h2>
              <form onSubmit={handleLogin}>
                <div className="form-group text-start mt-3">
                  <label className="form-label fx-6">Email</label>
                  <Input
                    size="large"
                    id="email"
                    name="email"
                    value={formData.email}
                    className={`form-control ${
                      errors.email ? "border-3 border-danger" : ""
                    }`}
                    placeholder="Please enter your Email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-danger fw-bold mt-1">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group text-start mt-3">
                  <label className="form-label fs-6">Password</label>
                  <Input.Password
                    id="password"
                    name="password"
                    value={formData.password}
                    className={`input-password form-control ${
                      errors.password ? "border-3 border-danger" : ""
                    }`}
                    placeholder="Please enter your Password"
                    onChange={handleChange}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeFilled
                          className="passwordIcon"
                          onClick={() => setShowPassword(true)}
                        />
                      ) : (
                        <EyeInvisibleFilled
                          className="passwordIcon"
                          onClick={() => setShowPassword(false)}
                        />
                      )
                    }
                  />
                  {errors.password && (
                    <div className="text-danger fw-bold mt-1">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end mt-3 mb-3">
                  <Link
                    to="/forgotpassword"
                    className="text-danger fw-bold text-decoration-none"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <button
                  type="sumbit"
                  danger
                  className="btn btn-lg btn-danger rounded-3 fs-5 btn-sm w-100"
                  style={{
                    height: "45px",
                    transition: "background-color 1s ease",
                  }}
                >
                  Login
                </button>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Login;
