import React, { useState, useEffect } from "react";
import { updatePasswrord } from "../services/api";
import VeroltLogo from "../assets/Logo/verolt-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Input, message, notification, Spin } from "antd";
import { EyeInvisibleFilled, EyeFilled, LoadingOutlined } from "@ant-design/icons";
import Navbar from "../components/Navbar/Navbar";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  useEffect(() => {
    if (confirmPassword && newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match!");
    } else {
      setPasswordError("");
    }
  }, [newPassword, confirmPassword]);

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match!");
      message.error("Passwords do not match!");
      return;
    }

    setLoading(true);
    const userdata = { email, new_password: newPassword };
    try {
      const response = await updatePasswrord(userdata);
      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "Password updated",
        });
        navigate("/");
      } else {
        notification.error({
          message: "Error",
          description: "Failed to update password. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "You are not authorized to change the password!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fs-reset pb-4">
      <Spin
        spinning={loading}
        tip="Please wait.."
        indicator={<LoadingOutlined size={40} spin />}
      >
        <Navbar link={""} />
        <div
          className="fs-reset-password-container row d-flex align-items-center justify-content-center m-auto">
          <div className="col-1 col-md-3 col-xl-4"></div>
          <div className="col-10 col-md-6 col-xl-4">
            <Card className="bg-dark text-white rounded-2 border-danger mt-4">
              <img
                className="d-block m-auto"
                src={VeroltLogo}
                width="60"
                height="50"
                alt="logo"
              />
              <h5 className="text-danger text-center mb-4">VeSense</h5>
              <h4 className="text-center fw-bold">Reset Password</h4>
              <div className="form-group text-start mt-3">
                <label className="form-label fs-6">New Password</label>
                <Input.Password
                  placeholder="Enter new password"
                  value={newPassword}
                  className="form-control input-password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <EyeFilled /> : <EyeInvisibleFilled />
                  }
                />
              </div>
              <div className="form-group text-start mt-3">
                <label className="form-label fs-6">Confirm Password</label>
                <Input.Password
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  className="form-control input-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <EyeFilled /> : <EyeInvisibleFilled />
                  }
                />
                {passwordError && (
                  <div className="text-danger mt-1">{passwordError}</div>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-danger btn-block w-100 mt-4 "
                onClick={handleSubmit}
              >
                Reset Password
              </button>
            </Card>
          </div>
          <div className="col-1 col-md-3 col-xl-4"></div>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPassword;
