import { useState, useEffect } from "react";
import { notification } from "antd";
import { getUserDetails } from "../services/api";

const useFetchAndFilterData = (role) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAndFilterData = async () => {
    setLoading(true);
    try {
      const response = await getUserDetails();
      const filteredData = response.data
        .filter((user) => user.access_type === role)
        .map((user, index) => ({
          ...user,
          srNo: index + 1,
          name: user.name,
          mobile: user.mobile_number,
          role:
            user.access_type.charAt(0).toUpperCase() +
            user.access_type.slice(1),
        }));
      setData(filteredData);
    } catch (error) {
      notification.error({
        message: "Error",
        description: `Failed to fetch ${role} data`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, fetchAndFilterData };
};

export default useFetchAndFilterData;
