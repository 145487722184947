// all api functions will be written and exported from here
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const handleRequest = async (method, url, data) => {
  try {
    const fullUrl = `${apiUrl}${url}`;
    const response = await axiosInstance({
      method,
      url: fullUrl,
      data,
    });
    return response;
  } catch (error) {
    const errorDetails = {
      message: error.message,
      status: error.response ? error.response.status : null,
      data: error.response ? error.response.data : null,
    };
    console.error("API error:", errorDetails);
    return errorDetails; // Return detailed error object
  }
};

export const adminLogin = (userData) =>
  handleRequest("post", "/login/adminlogin", userData);

export const addUser = (userData) =>
  handleRequest("post", "/login/adduser", userData);

export const removeUser = (userData) =>
  handleRequest("post", "/login/removeuser", userData);

export const getUserDetails = () => handleRequest("get", "/app/fetchusers");

export const getProfileDetails = (userId) =>
  handleRequest("post", "/login/adminprofile", userId);

export const getOTPEmail = (userData) =>
  handleRequest("post", "/login/sendemail", userData);

export const validateOtp = (userData) =>
  handleRequest("post", "/login/validateotp", userData);

export const updatePasswrord = (userData) =>
  handleRequest("post", "/login/changepwd", userData);

export const getBarChartDetails = () =>
  handleRequest("get", "/app/fetchdepartments");

export const getWordCloudDetails = () =>
  handleRequest("get", "/app/fetchkeywordcount");

export const getFeedbackDetails = () =>
  handleRequest("get", "/app/fetchallfeedbacks");

export const numberValidator = (value) => value.replace(/[^0-9+]/g, "");

export const feedbackData = [
  {
    key: "1",
    sr_no: "1",
    empId: "E0123",
    name: "John Brown",
    department: "HR",
    rating: "5",
    feedback:
      "Excellent performance and a strong contributor to team projects. Always willing to go the extra mile to help others.",
    date: "2024-08-21",
  },
  {
    key: "2",
    sr_no: "2",
    empId: "E0124",
    name: "Joe Black",
    department: "HMI",
    rating: "4",
    feedback: "Good work but needs improvement in communication.",
    date: "2024-08-20",
  },
  {
    key: "3",
    sr_no: "3",
    empId: "E0125",
    name: "Jim Green",
    department: "IT",
    rating: "3",
    feedback:
      "Average performance with consistent output, room for improvement.",
    date: "2024-08-19",
  },
  {
    key: "4",
    sr_no: "4",
    empId: "E0126",
    name: "Jessica White",
    department: "Data Engineering",
    rating: "4",
    feedback:
      "Provides solid insights, but the quality of analysis can be inconsistent at times.",
    date: "2024-08-18",
  },
  {
    key: "5",
    sr_no: "5",
    empId: "E0123",
    name: "John Brown",
    department: "HR",
    rating: "5",
    feedback:
      "Excellent performance and a strong contributor to team projects. Always willing to go the extra mile to help others.",
    date: "2024-08-21",
  },
  {
    key: "6",
    sr_no: "6",
    empId: "E0124",
    name: "Joe Black",
    department: "HMI",
    rating: "4",
    feedback: "Good work but needs improvement in communication.",
    date: "2024-08-20",
  },
  {
    key: "7",
    sr_no: "7",
    empId: "E0125",
    name: "Jim Green",
    department: "IT",
    rating: "3",
    feedback:
      "Average performance with consistent output, room for improvement.",
    date: "2024-08-19",
  },
  {
    key: "8",
    sr_no: "8",
    empId: "E0126",
    name: "Jessica White",
    department: "Data Engineering",
    rating: "4",
    feedback:
      "Provides solid insights, but the quality of analysis can be inconsistent at times.",
    date: "2024-08-18",
  },
];

export const barChartData = [
  { name: "HR", feedbackCount: 10 },
  { name: "HMI", feedbackCount: 8 },
  { name: "IT", feedbackCount: 17 },
  { name: "Data Engineering", feedbackCount: 4 },
];

export const wordCloudStaticData = [
  { text: "Excellent", value: 1000 },
  { text: "Good", value: 300 },
  { text: "Average", value: 200 },
  { text: "Poor", value: 100 },
  { text: "Outstanding", value: 350 },
  { text: "Satisfactory", value: 250 },
  { text: "Needs Improvement", value: 150 },
  { text: "Exceptional", value: 300 },
  { text: "Fair", value: 180 },
  { text: "Unacceptable", value: 80 },
  { text: "Exemplary", value: 220 },
  { text: "Superb", value: 270 },
  { text: "Mediocre", value: 140 },
  { text: "Dissatisfactory", value: 120 },
  { text: "Remarkable", value: 290 },
  { text: "Impressive", value: 260 },
  { text: "Subpar", value: 110 },
  { text: "Commendable", value: 230 },
  { text: "Adequate", value: 210 },
  { text: "Lacking", value: 90 },
  { text: "Brilliant", value: 320 },
  { text: "Terrible", value: 60 },
  { text: "Exceptional", value: 310 },
  { text: "Fantastic", value: 340 },
  { text: "Unsatisfactory", value: 130 },
  { text: "Decent", value: 190 },
  { text: "Marvelous", value: 280 },
  { text: "Pitiful", value: 70 },
  { text: "Astonishing", value: 330 },
  { text: "Acceptable", value: 200 },
  { text: "Shoddy", value: 85 },
  { text: "Stellar", value: 300 },
  { text: "Horrible", value: 50 },
  { text: "Favorable", value: 240 },
  { text: "Inadequate", value: 100 },
  { text: "Unremarkable", value: 140 },
  { text: "Impeccable", value: 310 },
  { text: "Underwhelming", value: 95 },
  { text: "Praiseworthy", value: 250 },
];
