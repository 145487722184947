import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Popconfirm,
  message,
  notification,
  Spin,
  Flex,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { addUser, removeUser, numberValidator } from "../../../services/api";
import useFetchAndFilterData from "../../../hooks/useFetchAndFilterData";

const { Option } = Select;

const AddRemoveUserAdmin = ({ role }) => {
  const { data, loading, fetchAndFilterData } = useFetchAndFilterData(role);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      key: "srNo",
      width: 65,
    },
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      key: "emp_id",
      width: 130,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "auto",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
      width: "auto",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 110,
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <Flex  justify="space-around" >
          <Button
            type="button"
            className="btn btn-outline-warning d-flex align-items-center d-none"
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
          <Popconfirm
            title={`Are you sure to delete this ${role}?`}
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ secondary: true }}
            okButtonProps={{ danger: true }}
          >
            <Button
              type="button"
              className="btn btn-outline-danger d-flex align-items-center"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  const onAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalOpen(true);
  };

  const onEdit = (record) => {
    const [fname, lname] = record.name.split(" ");
    form.setFieldsValue({ ...record, fname, lname });
    setEditingRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingRecord) {
        await handleEdit(values);
      } else {
        await handleAdd(values);
      }
    } catch (error) {
      console.error(error);
      message.error("Please fill all fields correctly");
    }
  };

  const handleAdd = async (values) => {
    try {
      setFormLoading(true);
      const newData = {
        ...values,
        srNo: data.length + 1,
      };
      await addUser(newData);
      await fetchAndFilterData();
      notification.success({
        message: "Success",
        description: `${
          role.charAt(0).toUpperCase() + role.slice(1)
        } added successfully`,
        showProgress: true,
        pauseOnHover: true,
      });
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error(
        `Failed to add ${role.charAt(0).toUpperCase() + role.slice(1)}`
      );
    } finally {
      setFormLoading(false);
    }
  };

  const handleEdit = async (values) => {
    try {
      setFormLoading(true);
      //   TODO: Call edit api here
      notification.success({
        message: "Success",
        description: `${
          role.charAt(0).toUpperCase() + role.slice(1)
        } updated successfully`,
        showProgress: true,
        pauseOnHover: true,
      });
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error(
        `Failed to edit ${role.charAt(0).toUpperCase() + role.slice(1)} details`
      );
    } finally {
      setFormLoading(false);
    }
  };

  const onDelete = async (record) => {
    try {
      await removeUser({ emp_id: record.emp_id });
      notification.success({
        message: "Success",
        description: `${
          role.charAt(0).toUpperCase() + role.slice(1)
        } details deleted successfully`,
        showProgress: true,
        pauseOnHover: true,
      });
      fetchAndFilterData();
    } catch {
      notification.error({
        message: "Error",
        description: `Failed to delete ${
          role.charAt(0).toUpperCase() + role.slice(1)
        }`,
        showProgress: true,
        pauseOnHover: true,
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center  flex-column flex-md-row">
        <h4 className="px-1 text-danger">
          {role.charAt(0).toUpperCase() + role.slice(1)} Details
        </h4>
        <Button
          type="button"
          className="d-flex align-items-center btn btn-outline-danger mb-4"
          icon={<PlusOutlined />}
          onClick={onAdd}
        >
          Add New {role.charAt(0).toUpperCase() + role.slice(1)}
        </Button>
      </div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined size={48} spin />}
      >
        <Table
          columns={columns}
          dataSource={data}
          bordered
          locale={{
            emptyText: loading ? `Fetching ${role} data...` : "No data",
          }}
          scroll={{ x: 1000 }}
          pagination={false}
          rowKey="emp_id"
        />
      </Spin>
      <Modal
        title={
          editingRecord
            ? `Edit ${role.charAt(0).toUpperCase() + role.slice(1)}`
            : `Add New ${role.charAt(0).toUpperCase() + role.slice(1)}`
        }
        open={isModalOpen}
        onOk={handleOk}
        okText={editingRecord ? `Update ${role}` : `Add ${role}`}
        confirmLoading={formLoading}
        onCancel={handleCancel}
        okButtonProps={{ className: "btn btn-sm btn-danger" }}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Form.Item
            name="emp_id"
            label="Employee ID"
            rules={[{ required: true, message: "Please enter employee ID" }]}
          >
            <Input disabled={!!editingRecord} />
          </Form.Item>
          <Form.Item
            name="fname"
            label="First Name"
            rules={[
              { required: true, message: "Please enter first name" },
              { min: 3, message: "First name must be at least 3 characters" }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lname"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter last name" },
              { min: 3, message: "Last name must be at least 3 characters" }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              {
                type: "email",
                message: "Please enter a valid email",
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Contact Number"
            rules={[
              { required: true, message: "Please enter contact number" },
              {
                len: 10,
                message: "Please enter a valid contact number",
              }
            ]}
          >
            <Input
              maxLength={10}
              onChange={(e) =>
                form.setFieldValue("mobile", numberValidator(e.target.value))
              }
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            initialValue={role}
            rules={[{ required: true, message: "Please select a role" }]}
          >
            <Select disabled>
              <Option value="user">User</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRemoveUserAdmin;
