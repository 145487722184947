import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo/verolt.png";

const Navbar = ({ link }) => {
  return (
    <nav className="navbar text-white d-flex justify-content-left align-items-center ml-5">
      <Link className="navbar-brand text-white p-3" to={link}>
        <img src={Logo} width="70" height="75" alt="logo" />
      </Link>
    </nav>
  );
};
export default Navbar;
