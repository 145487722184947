import React, { useState, useEffect } from "react";
import { Card, Input, Row, Flex, Tabs, Spin } from "antd";
import { getProfileDetails } from "../../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { empIdState } from "../../states/state";

const SettingsPage = () => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  // const [formData, setFormData] = useState({
  //   currentPassword: "",
  //   newPassword: "",
  // });
  const admin_emp_id = useRecoilValue(empIdState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // TODO: api call to change password
  // };

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        if (admin_emp_id) {
          const response = await getProfileDetails({ admin_emp_id });
          setLoading(false);
          const { name, email, mobile_number, emp_id } = response.data;
          const [fname, lname] = name ? name.split(" ") : ["", ""];
          setProfileData({
            fname,
            lname,
            email,
            mobile_number,
            emp_id,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching profile details : ", error);
      }
    };

    fetchProfile();
  }, [admin_emp_id]);

  const items = [
    {
      key: "1",
      label: "Profile",
      children: (
        <div className="row mt-4">
          <Row className="profile-container">
            <Card title="Personal Information" className="border-0">
              <Flex
                justify="space-between"
                className="flex-column flex-md-row gap-3 gap-md-5"
              >
                <div className="first-name">
                  <label className="form-label fs-6">First Name</label>
                  <Input
                    type="text"
                    size="large"
                    readOnly
                    className="mb-md-3 w-100"
                    value={profileData.fname}
                  />
                </div>
                <div>
                  <label className="form-label fs-6">Last Name</label>
                  <Input
                    type="text"
                    size="large"
                    readOnly
                    className="mb-3"
                    value={profileData.lname}
                  />
                </div>
              </Flex>
              <div>
                <label className="form-label fs-6">Email</label>
                <Input
                  type="text"
                  size="large"
                  readOnly
                  className="mb-3"
                  value={profileData.email}
                />
              </div>
              <Flex className="flex-column flex-md-row gap-3 gap-md-5">
                <div>
                  <label className="form-label fs-6">Contact No.</label>
                  <Input
                    type="text"
                    size="large"
                    readOnly
                    className="mb-md-3"
                    value={profileData.mobile_number}
                  />
                </div>
                <div>
                  <label className="form-label fs-6">Employee Id</label>
                  <Input
                    type="text"
                    size="large"
                    readOnly
                    className="mb-3"
                    value={profileData.emp_id}
                  />
                </div>
              </Flex>
            </Card>
          </Row>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Change Password",
    //   children: (
    //     <div className="row mt-4">
    //       <Row className="change-password-container">
    //         <Card title="Change Password" className="border-0">
    //           <form onSubmit={handleSubmit}>
    //             <div className="mb-4">
    //               <label className="form-label fs-6">Current Password</label>
    //               <Input.Password
    //                 size="large"
    //                 id="password"
    //                 name="currentPassword"
    //                 value={formData.currentPassword}
    //                 onChange={handleChange}
    //               />
    //             </div>
    //             <div className="mb-4">
    //               <label className="form-label fs-6">New Password</label>
    //               <Input.Password
    //                 size="large"
    //                 id="password"
    //                 name="newPassword"
    //                 value={formData.newPassword}
    //                 onChange={handleChange}
    //               />
    //             </div>
    //             <button
    //               type="submit"
    //               className="fs-settings-update-password-btn btn btn-danger btn-block w-100"
    //             >
    //               Change Password
    //             </button>
    //           </form>
    //         </Card>
    //       </Row>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <h4 className="px-1 text-danger mb-3">Settings</h4>
      <Spin
        spinning={loading}
        tip="Ferching details.."
        indicator={<LoadingOutlined size={40} spin />}
        size="large"
      >
        <Tabs
          type="card"
          items={items}
          defaultActiveKey="1"
          inkBarColor="#FF0000"
        />
      </Spin>
    </div>
  );
};

export default SettingsPage;
