import React from "react";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { empIdState } from "../src/states/state";
import Dashboard from "./pages/Dashboard/Dashboard";
import LandingPage from "./pages/LandingPage";
import { Routes, Route } from "react-router-dom";
import OtpValidation from "./pages/OtpValidation";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./services/ProtectedRoute";
import "./styles/main.css";

const AppInitializer = () => {
  const setEmpId = useSetRecoilState(empIdState);

  React.useEffect(() => {
    const storedEmpId = sessionStorage.getItem("admin_emp_id");
    if (storedEmpId) {
      setEmpId(storedEmpId);
    }
  }, [setEmpId]);
  return null;
};

const App = () => {
  return (
    <RecoilRoot>
      <AppInitializer />
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/forgotpassword" element={<OtpValidation />} />
        <Route path="/verify-otp" element={<OtpValidation />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Routes>
    </RecoilRoot>
  );
};

export default App;
