import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { RiAdminFill, RiUserAddFill } from "react-icons/ri";
import { Layout, Menu, Button, Input, Drawer, Spin } from "antd";
import {
  Link,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  LoadingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import Overview from "./Overview";
import Settings from "./SettingsPage";
import Logo from "../../assets/Logo/verolt.png";
import AdminDetails from "./UserAdmin/AdminPage";
import UserDetails from "./UserAdmin/UserPage";
import { empIdState } from "../../states/state";
import { useResetRecoilState } from "recoil";

const { Sider, Header, Content } = Layout;

const Dashboard = () => {
  const params = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetAdminEmpId = useResetRecoilState(empIdState);
  const navigate = useNavigate();
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logoutahandler = () => {
    setLoading(true);
    sessionStorage.removeItem("admin_emp_id");
    sessionStorage.removeItem("isAuthenticated");
    resetAdminEmpId();
    setTimeout(() => navigate("/"), 1500);
  };

  return (
    <>
      <Spin
        spinning={loading}
        tip="Logging out"
        indicator={<LoadingOutlined size={40} spin />}
      >
        <Layout className="fs-dasboard__layout">
          <Sider
            className="fs-dashboard__side-panel"
            collapsible
            collapsed={collapsed}
            breakpoint={"lg"}
            onCollapse={onCollapse}
          >
            <img
              src={Logo}
              alt="Logo"
              className="mb-3"
              style={{
                width: 50,
                marginLeft: collapsed ? "22%" : "35%",
                marginTop: collapsed ? "15%" : "8%",
              }}
            />
            <Menu
              theme="dark"
              className="fs-dasboard__menu-wrapper"
              selectedKeys={params["*"]}
              mode="inline"
            >
              <Menu.Item key="overview">
                <LuLayoutDashboard className="anticon" />
                <span>Dashboard</span>
                <Link to="overview" />
              </Menu.Item>
              <Menu.Item key="add-remove-admin">
                <RiAdminFill className="anticon" />
                <span>Add/Remove Admin</span>
                <Link to="add-remove-admin" />
              </Menu.Item>
              <Menu.Item key="add-remove-user">
                <RiUserAddFill className="anticon" />
                <span>Add/Remove Users</span>
                <Link to="add-remove-user" />
              </Menu.Item>
              <Menu.Item key="settings">
                <IoSettingsOutline className="anticon" />
                <span>Settings</span>
                <Link to="settings" />
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ marginInlineStart: collapsed ? 80 : 200 }}>
            <Header className="fs-dashboard__header bg-white d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  className="vetrove-dashboard__collapsed-button mobile-hidden"
                />
                <div className="search-box mobile-hidden">
                  <Input type="text" placeholder="Search here" />
                  <FiSearch />
                </div>
                <FiSearch
                  className="mobile-visible search-icon"
                  onClick={showDrawer}
                />
                <Drawer
                  title="Search here"
                  placement={"top"}
                  width={500}
                  onClose={onClose}
                  open={open}
                >
                  <div className="search-box">
                    <Input type="text" placeholder="Search here" />
                    <FiSearch />
                  </div>
                </Drawer>
              </div>
              <div className="right-side d-flex align-items-center">
                <IoMdLogOut className="text-danger cursor-pointer" onClick={logoutahandler} />
              </div>
            </Header>

            <Content
              className="fs-dashboard__content-wrapper"
            >
              <Routes>
                <Route path="" element={<Navigate replace to="overview" />} />
                <Route path="overview" element={<Overview />} />
                <Route path="add-remove-admin" element={<AdminDetails />} />
                <Route path="add-remove-user" element={<UserDetails />} />
                <Route path="settings" element={<Settings />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </>
  );
};

export default Dashboard;
