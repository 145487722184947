import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import WordCloud from "react-d3-cloud";
import Highlighter from "react-highlight-words";
import {
  DownloadOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  Input,
  Modal,
  notification,
  Space,
  Spin,
  Table,
} from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  wordCloudStaticData,
  getBarChartDetails,
  getFeedbackDetails,
  getWordCloudDetails,
} from "../../services/api";
import ModalContent from "./ModalContent";

const Overview = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [expandedKeys, setExpandedKeys] = useState({});
  const searchInput = useRef(null);
  const [feedbackData, setFeedbackData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [wordCloudData, setWordCloudData] = useState([]);
  const [loading, setLoading] = useState(true);

  const OpenModal = (modal) => {
    let secondsToGo = 5;
    const instance = modal.error({
      title: "Calculated Rating Information",
      content: <ModalContent />,
      okText: "Close",
      okType: "danger",
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  const startModal = () => {
    return OpenModal(modal);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [barChartResponse, wordCloudResponse, feedbackResponse] =
          await Promise.all([
            getBarChartDetails(),
            getWordCloudDetails(),
            fetchWithRetry(getFeedbackDetails),
          ]);

        const formattedBarChartData = barChartResponse.data.map((item) => ({
          ...item,
          name: getInitials(item.name),
        }));

        setBarChartData(formattedBarChartData);
        setWordCloudData(wordCloudResponse.data);
        setFeedbackData(
          feedbackResponse.data.map((feedback, index) => ({
            ...feedback,
            formattedDate: formatDate(feedback.date),
            key: index,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        notification.error({
          message: "Error",
          description: `Failed to fetch data: ${
            error.message || "Please try again later"
          }`,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getInitials = (name) => {
    if (!name || name.trim() === "") {
      return "Anonymous";
    }
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
  };

  const fetchWithRetry = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i < retries - 1)
          await new Promise((resolve) => setTimeout(resolve, delay));
        else throw error;
      }
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div className="mb-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          className="mb-2 d-block"
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined className="search-icon" />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              handleReset(clearFilters);
              confirm();
            }}
            size="small"
          >
            Reset Filters
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        className="search-icon"
        style={{
          color: filtered ? "blue" : "white",
          fontSize: filtered ? "20px" : "15px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          backgroundColor="#ffc069"
          className="fs-dashboard-feedback-table-highlighter"
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Date and Time",
      dataIndex: "formattedDate",
      key: "formattedDate",
      ...getColumnSearchProps("formattedDate"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      ...getColumnSearchProps("department"),
    },
    {
      title: (
        <>
          Calculated Rating
          <InfoCircleOutlined
            onClick={startModal}
            style={{
              marginLeft: "10px",
              fontSize: "16px",
              alignSelf: "center",
              color: "#ffffff",
            }}
          />
        </>
      ),
      dataIndex: "calculated_rating",
      key: "calculated_rating",
      width: 220,
      sorter: (a, b) => a.calculated_rating - b.calculated_rating,
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("calculated_rating"),
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      width: 300,
      render: (text, record) => {
        const isExpanded = expandedKeys[record.key];
        const truncatedText =
          text.length > 30 ? `${text.slice(0, 30)}...` : text;

        return (
          <>
            <span>{isExpanded ? text : truncatedText}</span>
            {text.length > 30 && (
              <Button
                type="link"
                className="pl-0"
                onClick={() => toggleExpand(record.key)}
              >
                {isExpanded ? "See less" : "See more.."}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const fontSizeMapper = (word) => Math.log2(word.value) * 5;
  const rotate = (word) => word.value % 1;

  const downloadHandler = () => {
    const table = document.querySelector(".ant-table-content");

    feedbackData.forEach((feedback) => {
      if (feedback.feedback.length > 30 && !expandedKeys[feedback.key]) {
        toggleExpand(feedback.key);
      }
    });

    setTimeout(() => {
      const headers = Array.from(table.querySelectorAll("thead th")).map(
        (th) => th.innerText
      );
      const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) => {
        const cells = Array.from(row.querySelectorAll("td"));
        return cells.map((cell, index) => {
          if (index === 4) {
            const text = cell.innerText;
            const cleanText = text.replace(/See less$/, "").trim();
            return cleanText;
          }
          return cell.innerText;
        });
      });

      const tableData = [headers, ...rows];
      const worksheet = XLSX.utils.aoa_to_sheet(tableData);

      const columnWidths = calculateColumnWidths(tableData);
      worksheet["!cols"] = columnWidths;

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "feedback-report.xlsx");
    }, 1000);
  };

  const calculateColumnWidths = (tableData) => {
    return tableData[0].map((_, colIndex) => {
      const maxLength = Math.max(
        ...tableData.map((row) => (row[colIndex] || "").length)
      );
      return { wch: maxLength + 2 };
    });
  };

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined size={40} spin />}
      size="large"
    >
      {contextHolder}
      <div className="fs-dashboard-overview">
        <h4 className="px-1 text-danger">Report Dashboard</h4>
        <div className="row">
          <div className="col text-center bg-white border border-2 rounded-2 mb-3 mx-3 p-3 h-100 text-danger fw-normal fs-5">
            Department-wise Feedback
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={barChartData}>
                <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="feedbackCount" fill="skyblue" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="col text-center bg-white border border-2 rounded-2 mb-3 mx-3 p-3 h-100 text-danger fw-normal fs-5">
            Feedback Summary
            <ResponsiveContainer width="100%" height={300}>
              <WordCloud
                // data={
                //   wordCloudData.length ? wordCloudData : wordCloudStaticData
                // }
                // passing static data as actual data is not enough to show wordcloud
                data={wordCloudStaticData}
                fontSizeMapper={fontSizeMapper}
                rotate={rotate}
                spiral="rectangular"
                padding={1}
              />
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col text-start my-4 p-3 h-100 text-danger fw-normal fs-5">
          <Flex className="flex-column flex-md-row justify-content-md-between">
            <h4>List of Feedbacks</h4>
            <Button
              type="button"
              className="d-flex align-items-center btn btn-danger mb-4"
              icon={<DownloadOutlined />}
              onClick={downloadHandler}
            >
              Download Report
            </Button>
          </Flex>
          <Table
            columns={columns}
            dataSource={feedbackData}
            bordered
            rowKey="key"
            pagination={false}
            rowClassName={(record) =>
              record.calculated_rating <= 2 ? "highlight-row" : "custom-row"
            }
            tableLayout="auto"
            scroll={{ x: 1000 }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default Overview;
