import { Flex } from "antd";

const ModalContent = () => {
  return (
    <Flex vertical justify="center" align="center">
      <p className="fw-bold">1 Low (negative)</p>
      <p className="fw-bold">5 High (positive)</p>
    </Flex>
  );
};
export default ModalContent;
